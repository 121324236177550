<template>
  <f7-page>
    <f7-navbar title="闪电贷" back-link=" " sliding>
    </f7-navbar>
    <div class="bg">

      <!-- 内部圆角内容区域 -->
      <div class="bg-inner">

        <!-- 上半部分图片区域 -->
        <div class="top-image-box">
          <p>
            <img class="imgBox" src="../assets/bank/cmb/bg.jpg" />
            <img class="float-text-one" src="../assets/bank/cmb/出发.png">
            <img class="float-text-two" src="../assets/bank/cmb/速享品质.png">
          </p>
        </div>

        <!-- 图片下方、中间小图层区域 -->
        <div class="center-image-box">
          <img class="center-image" src="../assets/bank/cmb/cmsloan.png" />
        </div>
        
        <!-- 产品特点 -->
        <div class="product-feature">
          <f7-menu>
            <f7-chip outline text="产品特点"></f7-chip>
          </f7-menu>
          <p style="font-size: 14px; padding-top: 5px;">招商银行线上个人消费贷款，自助申请、纯信用无抵押、免担保、7X24小时随时可提款，按日计息，随借随还</p>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">线上申请额度最高<span class="text-part-red">20万</span>（系统自动审批额度、利率）</f7-col>
          </f7-row>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">期限最长<span class="text-part-red">36个月</span></f7-col>
          </f7-row>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">自主支付，放款至招行一卡通借记卡，<span class="text-part-red">最快60秒放款</span></f7-col>
          </f7-row>
        </div>

        <!-- 建额申请流程 -->
        <div class="product-feature">
          <f7-menu>
            <f7-chip outline text="建额申请流程"></f7-chip>
          </f7-menu>
          <p style="font-size: 14px; padding-top: 5px;"><span style="font-weight: bold;">第一步：</span>点击底部“立即申请”，在线申请额度</p>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" >需提前办理上海分行一类卡，可<span class="text-part-red">预约办卡</span>，后续银行将统一上门激活，详询客户经理</f7-col>
          </f7-row>

          <div style="padding-left: 15px; padding-right: 15px;">
          <div class="buttonSublit btnAnimation">
            <a onclick="_hmt.push(['_trackEvent', 'cmsBank_external_link_card', 'click'])">
              <f7-button fill round large href='https://html.m.cmbchina.com/IGateway/Jump.aspx?funcid=1175001&CorperationCode=T00386FAB000171000000300DFHKXM010491000000000000000000000000&BranchID=121&Organization=121522&RecmCode=' external>
                <span style="font-size: 20px;">预约办卡</span>
              </f7-button>
            </a>
          </div>
          </div>
          
          <p style="font-size: 14px; padding-top: 10px;"><span style="font-weight: bold;">第二步：</span>完成人脸识别</p>
          <p style="font-size: 14px; padding-top: 10px;"><span style="font-weight: bold;">第三步：</span>等待系统审批结果</p>
        </div>

        <!-- 优惠活动 -->
        <div class="product-feature">
          <f7-menu>
            <f7-chip outline text="优惠活动"></f7-chip>
          </f7-menu>
          <p style="font-size: 14px; padding-top: 5px;">上海分行3-4月闪电贷优惠活动，优惠利率最低至<span class="text-part-red">4.05%</span>，三档活动叠加，达标即可领取利率优惠券。（利率优惠券单笔提款可用，具体提款利率以放款时系统审批为准。）</p>
        </div>

        <!-- 合规提示 -->
        <div class="product-feature">
          <f7-menu>
            <f7-chip outline text="合规提示"></f7-chip>
          </f7-menu>
          <p style="font-size: 14px; padding-top: 5px;">根据监管规定，闪电贷仅用于<span class="text-part-red">消费、经营，不可用于购房、理财、股票等风险投资及其他违规领域</span>。若发生贷款用途违规，我行有权依据监管规定及贷款合同相关条款，暂停贷款额度并对违规贷款进行清收。</p>
        </div>


        <!-- 注意事项 -->
        <div class="product-feature">
          <f7-menu>
            <f7-chip outline text="注意事项"></f7-chip>
          </f7-menu>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">系统<span class="text-part-red">自动匹配还款方式</span>，如按月付息到期还本、等额本息等。</f7-col>
          </f7-row>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">如收到短信提示提升额度，可联系客户经理，<span class="text-part-red">有专人上门协助操作，额度最高可提升至30万</span>。</f7-col>
          </f7-row>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">3-4月上海分行闪电贷利率优惠最低至<span class="text-part-red">4.05%</span>，活动详情请咨询客户经理。</f7-col>
          </f7-row>
          <f7-row no-gap style="padding-top: 5px;">
            <f7-col width="5" style="text-align: center;">♦</f7-col>
            <f7-col width="95" style="font-size: 14px">如有疑问，可联系客户经理：胡经理，联系电话13122872317。</f7-col>
          </f7-row>
        </div>
      </div>

    </div>
    <div class="buttonSublit btnAnimation">
      <a onclick="_hmt.push(['_trackEvent', 'cmsBank_external_link_apply_loan', 'click'])">
        <f7-button fill round large href='https://cmbt.cn/5Ng9VkRYA?lr42qr=0204PTS121' external>
          立即申请
        </f7-button>
      </a>
    </div>
  </f7-page>
</template>


<style lang="less" scoped>
.bg {
  background: #C7112F;
  left:0%;
  right:0%;
  top:0%;
  padding: 15px;
}
.bg-inner {
  background: white;
  border-radius:20px;
  overflow: hidden;
  padding-bottom: 20px;
}
.top-image-box {
  >p{
  margin: 0;
  width: 100%;
  position: relative;
}
}

.center-image-box {
  left:0%;
  right:0%;
  padding-left: 7%;
  padding-top: 3%;
  .center-image {
    width: 40%;
  }
}

.chip {
  --f7-chip-text-color: rgb(200,25,46);
  --f7-chip-font-size: 15px;
  --f7-chip-font-weight: bold;
}

.imgBox {
  overflow: hidden;
  width: 100%;
  height: 60%;
  z-index: 0;
}
.float-text-one {
  z-index: 1;
  position: absolute;
  width: 45%;
  display: block;
  left: 7%;
  top: 12%;
}
.float-text-two {
  z-index: 1;
  position: absolute;
  width: 35%;
  display: block;
  top: 75%;
  left: 7%;
}

.product-feature {
  padding-top: 10px;
  padding-left: 7%;
  padding-right: 7%;
  font-size: 12px;
  >p {
    margin: 0;
  }
}
.text-part-red {
  color: rgb(200,25,46);
  // font-size: 14px;
  font-weight: bold;
}

.chip-outline {
  --f7-chip-outline-border-color: #BE0017;
}

.button-fill {
  background: -prefix-linear-gradient(left, rgb(200,25,46), #FFB6C1); 
  background: linear-gradient(to right, rgb(200,25,46), #FFB6C1);
}
.button-large {
  --f7-button-large-font-size: 25px;
}

</style>